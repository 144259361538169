import React, { useEffect, useState } from "react";
import contact from "../Assets/contact.jpeg";
import bg from "../Assets/aboutbg.png";
import contactbg from "../Assets/contactbg.png";
import ContactForm from "../Components/Forms/ContactForm";
import { FaEnvelope, FaPhone } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { fetchContactData } from "../Api/Api";
import MetaHelmet from "../Components/MetaData/MetaData";
import Loader from "../Components/Loader/Loader";
import Breadcrumb from "../Components/Breadcrumb/Breadcrumb";
import { useLocation } from "react-router-dom";
const Contact = () => {
  const [data, setData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const path = useLocation();
  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      try {
        const result = await fetchContactData();

        setData(result);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching contact data:", error);
      }
    };

    loadData();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  const breadcrumbLinks = [
    { name: "Home", path: "/" },
    { name: "Contact us", path: path },
  ];
  return (
    <div>
      {data && <MetaHelmet metaData={data.meta_data} />}
      {data?.banner && (
        <div>
          <div className="relative lg:mt-[100px]  mt-[170px]   top-0">
          {data.banner.left_logo_url &&<img src={data.banner.left_logo_url} alt="" className="md:w-[300px] w-[150px]  h-[150px] z-10  md:h-[300px] object-contain absolute md:left-[80px] left-[10px] top-[50px]"/> } 
         {data.banner.right_logo_url &&<img src={data.banner.right_logo_url} alt="" className="md:w-[300px] w-[150px]  h-[150px] z-10   md:h-[300px] object-contain absolute md:right-[80px] right-[10px] top-[50px]"/>}
        
            <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent " />
            <img
              src={data?.banner?.banner_url}
              alt={data?.banner?.title}
              className=" object-cover h-full md:h-auto  w-full"
            />
            <div className="absolute w-[80%] mx-auto  z-10  top-1/2   left-1/2 transform -translate-x-1/2 -translate-y-1/2    ">
              <h1 className="uppercase text-center text-white md:text-[40px] text-[20px]">Contact us</h1>
              <Breadcrumb links={breadcrumbLinks} />
            </div>
         
          </div>

          <div className="w-[85%] mx-auto pt-16">
            <h3 className="text-3xl font-semibold text-blue-900 mb-3">
              {data?.banner?.title}
            </h3>
          </div>

          <div className="w-[75%] mx-auto py-16">
            <ContactForm />
          </div>
        </div>
      )}
      {data?.contactInfo && (
        <div
          className="min-h-screen-height bg-cover  mb-16 bg-left-top lg:h-[300px] bg-no-repeat flex items-center justify-center"
          style={{ backgroundImage: `url(${data.contactInfo.bg_image_url})` }}
        >
          <div className="w-[80%] lg:ml-auto  ml-0 py-10">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="flex flex-col">
                <div className=" bg-[#F9F9F9] rounded-full p-2 w-max">
                  <FaEnvelope className="  text-[#F98083]  " />
                </div>

                <h3 className="text-base text-blue-900  mt-3">
                  {data.contactInfo.email_title}
                </h3>
                <p className="text-sm py-1 text-blue-900">
                  {data.contactInfo.email_sub_title}
                </p>
                <p className="text-sm text-red m-0">
                  {" "}
                  <a href={`mailto:${data.contactInfo.email_id}`}>
                    {data.contactInfo.email_id}
                  </a>
                </p>
              </div>

              <div className="flex flex-col">
                <div className=" bg-[#F9F9F9] rounded-full p-2 w-max">
                  <FaLocationDot className="  text-[#F98083]  " />
                </div>

                <h3 className="text-base text-blue-900 mt-3">
                  {data.contactInfo.address_title}
                </h3>
                <p className="text-sm py-1 text-blue-900">
                  {data.contactInfo.address_sub_title}
                </p>
                <p className="text-sm text-red m-0">
                  {data.contactInfo.address}
                </p>
              </div>

              <div className="flex flex-col">
                <div className=" bg-[#F9F9F9] rounded-full p-2 w-max">
                  <FaPhone className="  text-[#F98083]  " />
                </div>

                <h3 className="text-base text-blue-900 mt-3">
                  {data.contactInfo.phone_title}{" "}
                </h3>
                <p className="text-sm py-1 text-blue-900">
                  {data.contactInfo.phone_sub_title}
                </p>
                <p className="text-sm text-red m-0">
                  <a href={`tel:${data.contactInfo.phone_number}`}>
                    {" "}
                    {data.contactInfo.phone_number}
                  </a>{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div>
        <iframe
          src={data?.contactInfo?.google_map}
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default Contact;
