import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";

const AntibioticsCard = ({ data }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3   gap-8">
      {data?.map((antibiotic) => (
        <div
          key={antibiotic.id}
          
          className="bg-white rounded-lg shadow-lg border border-gray-300 p-4 overflow-hidden transform hover:-translate-y-2 transition-transform duration-300"
        >
          <SlideshowLightbox   modalClose={"clickOutside"} theme="lightbox">
          <img
            src={antibiotic.image_url}
            alt={antibiotic.title}
            className="w-full h-[250px] object-contain rounded-lg"
          /></SlideshowLightbox>
          <div className="pt-4">
            <h3 className="text-lg text-blue-800 font-semibold">{antibiotic.title}</h3>
            <p className="text-gray-700 my-1">{antibiotic.tag}</p>
            
          </div>
        </div>
      ))}
    </div>
  );
};

export default AntibioticsCard