import React, { useEffect, useState } from "react";
import AntibioticsCard from "../Components/Cards/Antibiotics";
import { useLocation, useParams } from "react-router-dom";
import { fetchDivisionData } from "../Api/Api";
import Loader from "../Components/Loader/Loader";
import Breadcrumb from "../Components/Breadcrumb/Breadcrumb";
import MetaHelmet from "../Components/MetaData/MetaData";


const Antibiotics = () => {
  const { divisionId } = useParams();
  const [data, setData] = useState(null);
  const path = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      try {
        const result = await fetchDivisionData(divisionId);
        setData(result);
        // Now that data is loaded, find the blog
        setIsLoading(false);
        // Set other blogs excluding the selected one
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching blogs details data:", error);
      }
    };

    loadData();
  }, [divisionId]); // Dependency on blogId
  if (isLoading) {
    return <Loader />;
  }

  const breadcrumbLinks = [
    { name: "Home", path: "/" },
    { name: data?.division?.title, path: path },
  ];

  return (
    <div>
       {data && <MetaHelmet metaData={data?.meta_data} />}
     
        <div>
          {data?.banner && (
            <div className="relative lg:mt-[100px] mt-[170px]  ">
                   {data.banner.left_logo_url &&<img src={data.banner.left_logo_url} alt="" className="md:w-[300px] w-[150px]  h-[150px] z-10  md:h-[300px] object-contain absolute md:left-[80px] left-[10px] top-[50px]"/> } 
         {data.banner.right_logo_url &&<img src={data.banner.right_logo_url} alt="" className="md:w-[300px] w-[150px]  h-[150px] z-10   md:h-[300px] object-contain absolute md:right-[80px] right-[10px] top-[50px]"/>}
        
              <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent " />
              <img
                src={data.banner.banner_url}
                alt=""
                className=" object-cover h-full md:h-auto   w-full"
              />
              <div className="absolute w-[80%] mx-auto  z-10  top-1/2   left-1/2 transform -translate-x-1/2 -translate-y-1/2    ">
                <h1 className="uppercase text-center text-white md:text-[40px] text-[20px]">
                  {data?.division?.title}
                </h1>
                <Breadcrumb links={breadcrumbLinks} />
              </div>
              
            </div>
          )}
          <div className="w-[85%] mx-auto py-16">
            <h3 className="text-3xl font-semibold text-blue-900 mb-8">
              {data?.division?.title}
            </h3>
            {data?.subDivisions?.length > 0 && (
              <AntibioticsCard data={data.subDivisions} />
            )}
          </div>{" "}
        </div>
       
    </div>
  );
};

export default Antibiotics;
