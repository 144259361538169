import React, { useEffect, useRef, useState } from "react";
import career from "../Assets/career.jpeg";
import bg from "../Assets/aboutbg.png";
import career1 from "../Assets/career1.png";
import { FaSearch } from "react-icons/fa";
import JobsAccordion from "../Components/Accordion/JobAccordion";
 
import { NavLink, useLocation } from "react-router-dom";
import { fetchCareersData } from "../Api/Api";
import MetaHelmet from "../Components/MetaData/MetaData";
import Loader from "../Components/Loader/Loader";
import Breadcrumb from "../Components/Breadcrumb/Breadcrumb";
const Careers = () => {
  const jobsSectionRef = useRef(null);

  const handleScrollToJobs = () => {
    jobsSectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const path = useLocation();
  const [data, setData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      try {
        const result = await fetchCareersData();

        setData(result);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching careers data:", error);
      }
    };

    loadData();
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  const breadcrumbLinks = [
    { name: "Home", path: "/" },
    { name: "Careers", path: path },
  ];
  return (
    <div>
      {data && <MetaHelmet metaData={data.meta_data} />}
      {data?.banner && (
        <div className="relative top-0 lg:mt-[100px]  mt-[170px]  ">
              {data.banner.left_logo_url &&<img src={data.banner.left_logo_url} alt="" className="md:w-[300px] w-[150px]  h-[150px] z-10  md:h-[300px] object-contain absolute md:left-[80px] left-[10px] top-[50px]"/> } 
         {data.banner.right_logo_url &&<img src={data.banner.right_logo_url} alt="" className="md:w-[300px] w-[150px]  h-[150px] z-10   md:h-[300px] object-contain absolute md:right-[80px] right-[10px] top-[50px]"/>}
        
          <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent " />
          <img
            src={data.banner.banner_url}
            alt=""
            className="object-cover  h-full md:h-auto w-full"
          />
          <div className="absolute w-[80%] mx-auto  z-10  top-1/2   left-1/2 transform -translate-x-1/2 -translate-y-1/2    ">
            <h1 className="uppercase text-center text-white md:text-[40px] text-[20px]">Careers</h1>
            <Breadcrumb links={breadcrumbLinks} />
          </div>
         
        </div>
      )}
      {data?.banner && (
        <div className="bg-white shadow-md ">
          <div className="w-[85%] mx-auto flex items-center justify-end">
            <div className="md:flex items-center justify-center p-4 hidden ">
              <div className="relative w-full max-w-md">
                <input
                  type="text"
                  placeholder="Search..."
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none "
                />
                <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              </div>
            </div>
            <div className="my-3 md:my-0">
              <button
                onClick={handleScrollToJobs}
                className="border border-black hover:border-red py-2 px-4 rounded-md text-sm font-medium transition-colors duration-300 ease-in-out transform hover:bg-red hover:text-white  active:opacity-70"
              >
                See All Open Positions
              </button>
            </div>
          </div>
        </div>
      )}

     

      <div ref={jobsSectionRef} className="w-[85%] mx-auto py-[50px]">
        {data?.careers?.length > 0 && (
          <JobsAccordion
            jobs={data.careers}
            title={data?.careerContent?.third_title}
            description={data?.careerContent?.third_description}
          />
        )}
      </div>
    </div>
  );
};

export default Careers;
