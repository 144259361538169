import NestedNav from "./Components/Navbar/Navbar";
import TopNav from "./Components/Navbar/TopNav";
import phone from "./Assets/phone.png";
import wapp from "./Assets/whatsapp.png";
import Home from "./Pages/Home";
import { Route, Routes } from "react-router-dom";
import Footer from "./Components/Footer/Footer";
import Antibiotics from "./Pages/Antibiotics";
import Careers from "./Pages/Careers";
import Contact from "./Pages/Contact";
import PhotoGallery from "./Pages/PhotoGallery";
import VideoGallery from "./Pages/VideoGallery";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsAndConditions from "./Pages/TermsAndConditions";
import ScrollToTop from "./Components/ScrollTop/ScrollTop";
import { fetchSiteData } from "./Api/Api";
import { useEffect, useState } from "react";
import NotFound from "./Components/NotFound/NotFound";

import About from "./Pages/About";
import AboutUs from "./Pages/AboutUs";

function App() {
  const [data, setData] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const result = await fetchSiteData();

        setData(result);
      } catch (error) {
        console.error("Error fetching site info data:", error);
      }
    };

    loadData();
  }, []);

  let w_number = data?.contactInfo?.phone_number.split(" ").join("");
  return (
    <div>
      <div className="fixed right-3  z-[999] bottom-10 ">
        <a href={`tel:${data?.contactInfo?.phone_number}`}>
          <img src={phone} alt="" />
        </a>
        <a href={`https://wa.me/${w_number}`} target="_blank">
          <img src={wapp} alt="" />
        </a>
      </div>
      <div className="fixed top-0 w-full  z-50">
        <div className="  w-full      shadow-md z-50">
          <TopNav
            data={data?.contactInfo}
            urls={data?.site_info}
            tag={data?.extraMetaTag}
          />
        </div>

        <div className="w-full  shadow-md border border-[#D6D6D6] relative z-50 mx-auto">
          <NestedNav
            logo={data?.site_info?.logo_url}
            aboutMenus={data?.aboutMenus}
          />
        </div>
      </div>

      <ScrollToTop tag={data?.extraMetaTag} />
      <Routes>
        <Route
          path="/"
          element={<Home sts={data?.site_info?.key_feature_bg_image_url} />}
        />
         <Route path="/about-us" element={<AboutUs/>} />
        <Route path="/about/:aboutId" element={<About />} />
        <Route path="/career" element={<Careers />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route
          path="/privacy-policy"
          element={<PrivacyPolicy data={data?.site_info.privacy_policy} />}
        />
        <Route
          path="/terms-and-conditions"
          element={
            <TermsAndConditions data={data?.site_info.terms_conditions} />
          }
        />
        <Route path="/photo-gallery" element={<PhotoGallery />} />
        <Route path="/video-gallery" element={<VideoGallery />} />
        <Route path="/division/:divisionId" element={<Antibiotics />} />
        <Route path="*" element={<NotFound />} />
      </Routes>

      {data?.site_info && (
        <Footer
          address={data?.contactInfo?.address}
          data={data?.site_info}
          tag={data?.extraMetaTag}
          product={data?.footerProducts}
        />
      )}
    </div>
  );
}

export default App;
