import React, { useEffect, useState } from "react";
import { Navbar, Nav, Dropdown } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import "./Navbar.css";
import styles from "./ScrollableComponent.module.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { fetchMenuData } from "../../Api/Api";

const renderMenuItems = (
  handleTitleClick,
  items,
  setMenuOpen,
  path,
  linkKLen
) => {
  return items.map((item, index) => {
    if (item.submenu) {
      let linkHeight = 44.5;
      if (item.submenu.length <= 5) {
        linkHeight = linkHeight * item.submenu.length;
      } else {
        linkHeight = 250;
      }

      if (linkKLen === 0) {
        linkHeight = 56;
      }
      return (
        <Dropdown
          title={
            item.title === "ABOUT US" ? (
              <span style={{ cursor: "pointer" }} onClick={handleTitleClick}>
                ABOUT US
              </span>
            ) : (
              item.title
            )
          }
          key={index}
          trigger="hover"
          style={{ minHeight: `${linkHeight}px` }}
        >
          {linkKLen === 1 ? (
            <div className={`${styles.scrollable}  `}>
              {renderMenuItems(
                handleTitleClick,
                item.submenu,
                setMenuOpen,
                path,
                linkKLen + 1
              )}
            </div>
          ) : (
            renderMenuItems(
              handleTitleClick,
              item.submenu,
              setMenuOpen,
              path,
              linkKLen + 1
            )
          )}
        </Dropdown>
      );
    }

    return (
      <Nav.Item key={index} className="md:w-auto w-full">
        <NavLink
          to={item.link}
          onClick={() => setMenuOpen(false)}
          className="  hover:text-[#F6C47E] hover:no-underline w-full  "
        >
          <span
            className={`${
              path === item.link ? "text-red" : "text-red"
            } w-full md:p-[20px] p-0`}
          >
            {" "}
            {item.title}{" "}
          </span>
        </NavLink>
      </Nav.Item>
    );
  });
};

const NestedNav = ({ logo, aboutMenus }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const handleTitleClick = () => {
    navigate("/about-us");
    setMenuOpen(false) // Navigate when the dropdown title is clicked
  };
  useEffect(() => {
    const loadData = async () => {
      try {
        const result = await fetchMenuData();

        setData(result);
      } catch (error) {
        console.error("Error fetching menu data:", error);
      }
    };

    loadData();
  }, []);
  const menuItems = [
    { title: "HOME", link: "/", id: 1 },
    {
      title: "ABOUT US",
      link: "/about-us",
      submenu: aboutMenus?.map((item) => ({
        title: item.title.toUpperCase(),
        link: `/about/${item.short_url}`,
      })),
    },

    {
      title: "DIVISIONS",
      link: "/services",
      submenu: data?.map((service) => ({
        title: service.title.toUpperCase(),
        link: `/division/${service.short_url}`,

        id: service.id,
      })),
    },
    {
      title: "MEDIA CENTER",
      link: "/media-center",
      submenu: [
        { title: "PHOTO GALLERY", link: "/photo-gallery", id: 12 },
        { title: "VIDEO GALLERY", link: "/video-gallery", id: 13 },
      ],
    },

    { title: "CONTACT US", link: "/contact-us", id: 17 },
  ];

  const menuItemMobile = [
    { title: "HOME", link: "/", id: 1 },
    {
      title: "ABOUT US",
      link: "/about-us",
      submenu: aboutMenus?.map((item) => ({
        title: item.title.toUpperCase(),
        link: `/about/${item.short_url}`,
      })),
    },
    {
      title: "DIVISIONS",
      link: "/services",
      submenu: data?.map((service) => ({
        title: service.title.toUpperCase(),
        link: `/division/${service.short_url}`,
        id: service.id,
      })),
    },
    {
      title: "MEDIA CENTER",
      link: "/media-center",
      submenu: [
        { title: "PHOTO GALLERY", link: "/photo-gallery", id: 12 },
        { title: "VIDEO GALLERY", link: "/video-gallery", id: 13 },
      ],
    },

    { title: "CAREERS", link: "/career", id: 17 },
    { title: "CONTACT US", link: "/contact-us", id: 17 },
  ];

  return (
    <Navbar>
      <div className="w-[96%] mx-auto flex items-center justify-between  ">
        <Navbar.Brand href="#">
          {" "}
          <NavLink to="/">
            {logo && <img src={logo} alt="" className="w-[130px]  " />}
          </NavLink>
        </Navbar.Brand>
        <button
          className="text-gray-800 focus:outline-none lg:hidden"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          {menuOpen ? (
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="black"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          ) : (
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="black"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          )}
        </button>
        <Nav className="hidden lg:flex">
          {renderMenuItems(
            handleTitleClick,
            menuItems,
            setMenuOpen,
            location.pathname,
            0
          )}
        </Nav>
      </div>
      {menuOpen && (
        <div className="lg:hidden">
          <Nav className="flex flex-col items-start  pl-6 w-full">
            {renderMenuItems(
              handleTitleClick,
              menuItemMobile,
              setMenuOpen,
              location.pathname,
              0
            )}
          </Nav>
        </div>
      )}
    </Navbar>
  );
};

export default NestedNav;
