import React, { useEffect, useState } from "react";
import freedom from "../Assets/freedom.jpeg";
import trust from "../Assets/trust.jpeg";
import Button from "../Components/Buttons/Button";
import { fetchAboutData } from "../Api/Api";
import MetaHelmet from "../Components/MetaData/MetaData";
import Loader from "../Components/Loader/Loader";
import Breadcrumb from "../Components/Breadcrumb/Breadcrumb";
import { useParams } from "react-router-dom";

const About = () => {
  const { aboutId } = useParams();
  const [data, setData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      try {
        const result = await fetchAboutData(aboutId);

        setData(result);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching about data:", error);
      }
    };

    loadData();
  }, [aboutId]);

  if (isLoading) {
    return <Loader />;
  }

  const breadcrumbLinks = [
    { name: "Home", path: "/" },
    { name: data?.page?.title, path: `/about/${data?.page?.short_url}` },
  ];

  return (
    <div>
      {data && <MetaHelmet metaData={data.meta_data} />}
      {data?.banner && (
        <div className="relative lg:mt-[100px] mt-[170px]  ">
           {data.banner.left_logo_url &&<img src={data.banner.left_logo_url} alt="" className="md:w-[300px] w-[150px]  h-[150px] z-10  md:h-[300px] object-contain absolute md:left-[80px] left-[10px] top-[50px]"/> } 
         {data.banner.right_logo_url &&<img src={data.banner.right_logo_url} alt="" className="md:w-[300px] w-[150px]  h-[150px] z-10   md:h-[300px] object-contain absolute md:right-[80px] right-[10px] top-[50px]"/>}
        
          <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent " />
          <img
            src={data.banner.banner_url}
            alt=""
            className="object-cover w-full  h-full md:h-auto"
          />
          <div className="absolute w-[80%] mx-auto  z-10  top-1/2   left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <h1 className="uppercase text-center text-white md:text-[40px] text-[20px]">
              {data?.page?.title}
            </h1>
            <Breadcrumb links={breadcrumbLinks} />
          </div>
        </div>
      )}
      
      {data?.page?.description && (
        <div className="flex flex-col md:flex-row items-stretch gap-[25px] w-[85%] md:w-[1150px] mx-auto py-20">
          {/* Image Section */}
          <div className="w-full md:w-1/2 flex-1 flex">
            <img
              src={data.page.image_url}
              alt="Placeholder"
              className="w-full h-full object-cover"
            />
          </div>
      
          {/* Text Section */}
          <div className="w-full md:w-1/2 md:px-6 flex flex-col">
            <h1 className="text-3xl md:text-4xl font-bold text-blue-900 mb-4">
              {data?.page?.title}
            </h1>
            <div className="text-lg md:text-xl text-blue-900">
              <div
                dangerouslySetInnerHTML={{
                  __html: data.page.description,
                }}
              />
            </div>
          </div>
        </div>
      )}
      

    </div>
  );
};

export default About;
