import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import logo1 from "../Assets/Gb-Logo.png";
import logo2 from "../Assets/Zuvan-Logo.png";
import { fetchHomeData } from "../Api/Api";
import MetaHelmet from "../Components/MetaData/MetaData";
import Loader from "../Components/Loader/Loader";
import LazyLoad from "react-lazyload";

// Custom Next Arrow
const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <button
      className="absolute right-5 hidden md:block md:top-1/2 top-[65%] transform -translate-y-1/2 bg-white/30 backdrop-blur-md text-2xl font-bold text-white p-3 rounded-md z-10 shadow-lg border border-white/20"
      onClick={onClick}
    >
      ᐳ
    </button>
  );
};

// Custom Previous Arrow
const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <button
      className="absolute left-5 hidden md:block md:top-1/2 top-[65%] transform -translate-y-1/2  bg-white/30 backdrop-blur-md   text-white p-3 text-2xl font-bold  rounded-md z-10 shadow-lg border border-white/20"
      onClick={onClick}
    >
      ᐸ
    </button>
  );
};

const Home = ({ sts }) => {
  const [data, setData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      try {
        const result = await fetchHomeData();

        setData(result);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching home data:", error);
      }
    };

    loadData();
  }, []);
  const settings = {
    dots: false,
    arrows: true,
    autoplay: true,
    infinite: data?.homeBanner?.length > 1 ? true : false,
    speed: 600,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    swipe: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: data?.homeBanner?.length > 1 && <NextArrow />,
    prevArrow: data?.homeBanner?.length > 1 && <PrevArrow />,
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      {data && <MetaHelmet metaData={data.meta_data} />}

      {data?.homeBanner?.length > 0 && (
        <div>
          <Slider {...settings}>
            {data.homeBanner.map((item) => (
              <div className="relative">
                {item.left_logo_url && (
                  <img
                    src={item.left_logo_url}
                    alt=""
                    className="md:w-[300px]   z-10  md:h-[300px] h-[150px] w-[150px] object-contain absolute md:left-[80px] left-[10px] md:top-[150px] top-[200px]"
                  />
                )}
                {item.right_logo_url && (
                  <img
                    src={item.right_logo_url}
                    alt=""
                    className="md:w-[300px]   z-10  md:h-[300px] h-[150px] w-[150px] object-contain absolute md:right-[80px] right-[10px] md:top-[150px] top-[200px]"
                  />
                )}
                <LazyLoad height={200} key={item.id}>
                  <img
                    src={item.banner_url}
                    alt={"banner.title"}
                    className="w-full     mt-[170px] md:mt-[100px]  object-cover"
                  />
                </LazyLoad>
              </div>
            ))}
          </Slider>
        </div>
      )}

      {data?.keyFeatures?.length > 0 && sts && (
        <LazyLoad height={200}>
          <img src={sts} alt="" className="w-full  " />
        </LazyLoad>
      )}
    </div>
  );
};

export default Home;
