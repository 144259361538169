import React from "react";
import { NavLink } from "react-router-dom";

const PrivacyPolicy = ({ data }) => {
  return (
    <div>
      <div className="w-[85%] pt-24 mx-auto my-14">
        {data && (
          <h1 className="text-3xl font-semibold text-blue-900 uppercase mb-4">
            PRIVACY POLICY
          </h1>
        )}
        <div
          dangerouslySetInnerHTML={{
            __html: data,
          }}
        />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
